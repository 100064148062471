import {authAxios as axios, customAxios as axios2, getHeader, unizAxios, xTravelAxios} from "../../request";
import {
    addAgentUrl, getTokenUrl, listAllBranchUrl,
    listCountriesUrl,
    loginSendOtpUrl,
    loginSubmitLoginUrl,
    RecoverPasswordUrl,
    resendOtpUrl, ResetPasswordUrl, singleUserUpdateUrl,
    singleUserUrl, testingUrl,
    updateUserPasswordUrl,
    logoutUrl, clearCookiesUrl
} from "./api";
import {notification} from "../../components/Elements";
import {showLoader, hideLoader} from "../../actions/loader";

export const loginSendOtpFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(loginSendOtpUrl(), valData);
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
}

export const loginSubmitLoginFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(loginSubmitLoginUrl(), valData);
    dispatch(hideLoader())
    if (!data.error) {
        /* notification.success({
             message: data.message || 'Success'
         })*/
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
}

export const resendOtpFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(resendOtpUrl(), valData);
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
}

export const getUser = () => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios2.post(singleUserUrl(), {})
    dispatch(hideLoader())
    return data
}
export const recoverPassword = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(RecoverPasswordUrl(), valData)
    if (!data.error) {
        /*  notification.success({
            message: data.message || 'Success'
          })*/
        dispatch(hideLoader())
    } else {
        /* notification.error({
           message: data.message || 'Error'
         })*/
        dispatch(hideLoader())
    }
    return data
}
export const resetPasswordFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(ResetPasswordUrl(), valData)
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
        dispatch(hideLoader())
    } else {
        notification.error({
            message: data.message || 'Error'
        })
        dispatch(hideLoader())
    }
    return data
}

export const updateUserPassword = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios2.post(updateUserPasswordUrl(), valData)
    dispatch(hideLoader())

    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Success'
        })
    }

    return data
}
export const listAllCountries = (filters) => async (dispatch) => {
    let config = {params: {...filters}}
    let {data} = await unizAxios.get(listCountriesUrl(), config);
    if (data.error) {
        notification.error({
            message: data.message || 'Error',
        })
    }
    return data.data;

}
export const addAgent = (formData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await xTravelAxios.post(addAgentUrl(), formData)
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const testingFxn = async (formData) => {
    let {data} = await axios2.post(testingUrl(), formData)
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const updateUser = (valData, id) => async dispatch => {
    dispatch(showLoader())
    let {data} = await unizAxios.put(singleUserUpdateUrl(id), valData)
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || `Updated Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
    }
    return data
}

export const getUserData = id => async dispatch => {
    dispatch(showLoader())
    let {data} = await unizAxios.get(singleUserUpdateUrl(id), {})
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}

export const getTokenFxn = () => async dispatch => {
    let {data} = await unizAxios.get(getTokenUrl(), {withCredentials: true})
    dispatch(hideLoader())
    return data
}
export const logoutLogsFxn = async (valData) => {
    let config = {
        ...getHeader(),
        withCredentials: true
    }
    let {data} = await unizAxios.post(logoutUrl(), {logsFrom: 'Web', ...valData}, config)
    return data
}

export const clearCookiesFxn = async (valData) => {
    let config = {
        ...getHeader(),
        withCredentials: true
    }
    let {data} = await unizAxios.post(clearCookiesUrl(), {logsFrom: 'Web', ...valData}, config)
    return data
}
