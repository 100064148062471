import {clearCookiesFxn, logoutLogsFxn} from "../containers/authications/action";

const exp = {
    logout: (dispatch, user) => {
        clearCookiesFxn(user).then(() => {
        })
        setTimeout(() => {
            localStorage.clear();
            dispatch({
                type: "SET_CURRENT_USER",
                user: {},
            });
            window.location.href = "/login";
        }, 100);
    }
}
export default exp
